import Dashboard from "layouts/dashboard";
import SignIn from "layouts/authentication/sign-in";
import SendMessagePage from "layouts/message/sendMessage";
import CreateTemplate from "layouts/templates/createTemplate";
import TemplateListPage from "layouts/templates/templateList";
import MessageListPage from "layouts/message/messageList";
import ContactListPage from "layouts/contacts/contactList";
import AddContact from "layouts/contacts/addContact";
import OTPVerification from "layouts/authentication/otpVerification";
import AddContactGroup from "layouts/contacts/addContactGroup";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    displayInSidebar: true,
  },
  {
    type: "collapse",
    name: "SendMessage",
    key: "sendMessage",
    icon: <Icon fontSize="small">send</Icon>,
    route: "/sendMessage",
    component: <SendMessagePage />,
    displayInSidebar: true,
  },
  {
    type: "collapse",
    name: "Track Messages",
    key: "trackMessages",
    icon: <Icon fontSize="small">track_changes</Icon>,
    route: "/tracking",
    component: <MessageListPage />,
    displayInSidebar: true,
  },
  {
    type: "collapse",
    name: "Template List",
    key: "templateList",
    icon: <Icon fontSize="small">view_list</Icon>,
    route: "/templateList",
    component: <TemplateListPage />,
    displayInSidebar: true,
  },
  {
    type: "collapse",
    name: "Create Template",
    key: "createTemplate",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/templateCreation",
    component: <CreateTemplate />,
    displayInSidebar: false,
  },
  {
    type: "collapse",
    name: "Edit Template",
    key: "editTemplate",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/templateCreation/:templateId",
    component: <CreateTemplate />,
    displayInSidebar: false,
  },
  {
    type: "collapse",
    name: "Contact List",
    key: "contactList",
    icon: <Icon fontSize="small">contacts</Icon>,
    route: "/contactList",
    component: <ContactListPage />,
    displayInSidebar: true,
  },
  {
    type: "collapse",
    name: "Add Contact",
    key: "addContact",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/contactCreation",
    component: <AddContact />,
    displayInSidebar: false,
  },
  {
    type: "collapse",
    name: "Edit Contact",
    key: "editContact",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/contactCreation/:contactId",
    component: <AddContact />,
    displayInSidebar: false,
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "signIn",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
    displayInSidebar: false,
  },
  {
    type: "collapse",
    name: "OTP Verification",
    key: "otpVerification",
    icon: <Icon fontSize="small">verified_user</Icon>,
    route: "/auth/2fa",
    component: <OTPVerification />,
    displayInSidebar: false,
  },
  {
    type: "collapse",
    name: "Add Contact Group",
    key: "addContactGroup",
    icon: <Icon fontSize="small">add</Icon>,
    route: "/contactGroupCreation",
    component: <AddContactGroup />,
    displayInSidebar: false,
  },
  {
    type: "collapse",
    name: "Edit Contact Group",
    key: "editContactGroup",
    icon: <Icon fontSize="small">edit</Icon>,
    route: "/contactGroupCreation/:groupId",
    component: <AddContactGroup />,
    displayInSidebar: false,
  },
];

export default routes;
