import React, { useState, useEffect } from "react";
import { TextField, MenuItem, Card, Box, Container, InputAdornment } from "@mui/material";
import { Formik, Form, FieldArray } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";

const SendMessagePage = () => {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const handleSendBroadcastClick = async (values) => {
    const apiUrl =
      "https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/message";
    const recipients = values.recipients.map((recipient) => recipient);

    const apiData = {
      templateName: values.templateName,
      language: values.language,
      recipients: recipients,
    };

    console.log("Sending data to API:", apiData);

    try {
      const response = await axios.post(apiUrl, apiData);
      console.log("API Response:", response.data);
      toast.success("Broadcast message sent successfully!");
    } catch (error) {
      console.error(
        "Broadcast message sending error:",
        error.response ? error.response.data : error.message
      );
      toast.error("Failed to send broadcast message. Please try again.");
    }
  };

  const fetchTemplates = async () => {
    const key = "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz";
    try {
      const response = await axios.get(
        "https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/template",
        {
          headers: {
            ContentType: "application/json",
            apiKey: key,
          },
        }
      );
      console.log("Templates fetching response:", response);
      setTemplates(response.data.body || []);
    } catch (error) {
      console.error("Templates fetching error:", error);
      toast.error("Failed to fetch templates. Please try again.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Container maxWidth="md">
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              style={{ backgroundColor: "#24272b" }}
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Send Broadcast Message
              </MDTypography>
            </MDBox>
            <Box p={3}>
              <Formik
                initialValues={{ recipients: [""], templateName: "", language: "en_US" }}
                onSubmit={(values) => {
                  handleSendBroadcastClick(values);
                }}
              >
                {({ handleChange, values }) => (
                  <Form>
                    <FieldArray
                      name="recipients"
                      render={(arrayHelpers) => (
                        <div>
                          {values.recipients.map((recipient, index) => (
                            <Grid container spacing={3} key={index}>
                              <Grid item xs={12}>
                                <TextField
                                  fullWidth
                                  label="Recipient"
                                  name={`recipients[${index}]`}
                                  value={recipient}
                                  onChange={handleChange}
                                  variant="outlined"
                                  required
                                  margin="normal"
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          onClick={() => arrayHelpers.remove(index)}
                                          edge="end"
                                          color="secondary"
                                        >
                                          <RemoveIcon />
                                        </IconButton>
                                      </InputAdornment>
                                    ),
                                  }}
                                  sx={{
                                    "& .MuiOutlinedInput-root": {
                                      height: "3rem",
                                    },
                                  }}
                                />
                              </Grid>
                            </Grid>
                          ))}
                          <Button
                            type="button"
                            variant="outlined"
                            style={{
                              borderColor: "black",
                              color: "black",
                              marginTop: 10,
                            }}
                            onClick={() => arrayHelpers.push("")}
                            startIcon={<AddIcon style={{ color: "blue" }} />}
                          >
                            Add Recipient
                          </Button>
                        </div>
                      )}
                    />
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          select
                          fullWidth
                          label="Template Name"
                          name="templateName"
                          value={values.templateName}
                          onChange={handleChange}
                          variant="outlined"
                          required
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "3rem",
                            },
                          }}
                        >
                          {Array.isArray(templates) &&
                            templates.map((template) => (
                              <MenuItem key={template.name} value={template.name}>
                                {template.name}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          select
                          fullWidth
                          label="Language"
                          name="language"
                          value={values.language}
                          onChange={handleChange}
                          variant="outlined"
                          required
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "3rem",
                            },
                          }}
                        >
                          <MenuItem value="en_US">English (US)</MenuItem>
                          <MenuItem value="es_ES">Spanish</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ backgroundColor: "#24272b", color: "white" }}
                          size="large"
                        >
                          <MDTypography variant="h6" color="white">
                            Send Broadcast
                          </MDTypography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Card>
        </Container>
      </MDBox>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default SendMessagePage;
