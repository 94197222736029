import React, { useEffect, useState } from "react";
import {
  TextField,
  MenuItem,
  Card,
  Box,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Formik, Form, FieldArray } from "formik";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import AddIcon from "@mui/icons-material/Add";

const CreateTemplate = () => {
  const { templateId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [initialValues, setInitialValues] = useState({
    templateName: "",
    category: "MARKETING",
    language: "en_US",
    bodyText: "",
    header: false,
    headerText: "",
    footer: false,
    footerText: "",
    buttons: [],
  });

  useEffect(() => {
    const fetchTemplate = async () => {
      if (templateId) {
        console.log("Fetching template with ID:", templateId);
        const key = "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz";
        const apiUrl = `https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/template`;
        try {
          const response = await axios.get(
            apiUrl,
            {
              params: {
                id: templateId,
              },
            },
            {
              headers: {
                ContentType: "application/json",
                apiKey: key,
              },
            }
          );
          console.log("Response of fetching template:", response.data.body);
          const { name, category, components } = response.data.body;
          setInitialValues({
            templateName: name,
            category,
            language: "en_US",
            bodyText: components?.find((c) => c.type === "BODY")?.text || "",
            header: !!components?.find((c) => c.type === "HEADER"),
            headerText: components?.find((c) => c.type === "HEADER")?.text || "",
            footer: !!components?.find((c) => c.type === "FOOTER"),
            footerText: components?.find((c) => c.type === "FOOTER")?.text || "",
            buttons: components?.find((c) => c.type === "BUTTONS")?.buttons || [],
          });
        } catch (error) {
          console.error("Error fetching template:", error);
          toast.error("Failed to fetch template. Please try again.");
        }
      }
    };

    fetchTemplate();
  }, [templateId]);

  const handleCreateTemplate = async (values, resetForm) => {
    const key = "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz";
    const apiUrl =
      "https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/template";
    const components = [];

    if (values.header) {
      components.push({
        type: "HEADER",
        format: "TEXT",
        text: values.headerText,
      });
    }

    components.push({
      type: "BODY",
      text: values.bodyText,
    });

    if (values.footer) {
      components.push({
        type: "FOOTER",
        text: values.footerText,
      });
    }

    if (values.buttons.length > 0) {
      const buttons = values.buttons.map((button) => ({
        type: button.type,
        text: button.text,
        ...(button.type === "PHONE_NUMBER" && { phone_number: button.phoneNumber }),
        ...(button.type === "URL" && { url: button.url }),
      }));
      components.push({
        type: "BUTTONS",
        buttons,
      });
    }

    const apiData = {
      name: values.templateName,
      category: values.category,
      language: values.language,
      components,
    };

    try {
      const response = await axios.post(apiUrl, apiData, {
        headers: {
          ContentType: "application/json",
          apiKey: key,
        },
      });
      console.log("Response of template creation:", response);
      if (response.data && Object.keys(response.data).length === 0) {
        toast.warn("Response is empty. Check server logs for more details.");
      } else {
        toast.success("Template created successfully!");
        resetForm();
        navigate("/templateList");
      }
    } catch (error) {
      console.error(
        "Template creation error:",
        error.response ? error.response.data : error.message
      );
      toast.error("Failed to create template. Please try again.");
    }
  };

  const handleEditTemplate = async (values, resetForm) => {
    const key = "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz";
    const apiUrl =
      "https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/template";
    const components = [];

    if (values.header) {
      components.push({
        type: "HEADER",
        format: "TEXT",
        text: values.headerText,
      });
    }

    components.push({
      type: "BODY",
      text: values.bodyText,
    });

    if (values.footer) {
      components.push({
        type: "FOOTER",
        text: values.footerText,
      });
    }

    if (values.buttons.length > 0) {
      const buttons = values.buttons.map((button) => ({
        type: button.type,
        text: button.text,
        ...(button.type === "PHONE_NUMBER" && { phone_number: button.phoneNumber }),
        ...(button.type === "URL" && { url: button.url }),
      }));
      components.push({
        type: "BUTTONS",
        buttons,
      });
    }
    try {
      const response = await axios.put(
        apiUrl,
        {
          templateId: templateId,
          category: values.category,
          components,
        },
        {
          headers: {
            ContentType: "application/json",
            apiKey: key,
          },
        }
      );
      console.log("Response of template editing:", response);
      toast.success("Template updated successfully!");
      resetForm();
      navigate("/templateList");
    } catch (error) {
      console.error(
        "Template editing error:",
        error.response ? error.response.data : error.message
      );
      toast.error("Failed to update template. Please try again.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Container maxWidth="md">
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              style={{ backgroundColor: "#24272b" }}
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {templateId ? "Edit Template" : "Create Template"}
              </MDTypography>
            </MDBox>
            <Box p={3}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                  templateId
                    ? handleEditTemplate(values, resetForm)
                    : handleCreateTemplate(values, resetForm);
                }}
              >
                {({ handleChange, values }) => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Template Name"
                          name="templateName"
                          value={values.templateName}
                          onChange={handleChange}
                          variant="outlined"
                          required
                          margin="normal"
                          disabled={!!templateId}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          select
                          fullWidth
                          label="Category"
                          name="category"
                          value={values.category}
                          onChange={handleChange}
                          variant="outlined"
                          required
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "3rem",
                            },
                          }}
                        >
                          <MenuItem value="AUTHENTICATION">Authentication</MenuItem>
                          <MenuItem value="MARKETING">Marketing</MenuItem>
                          <MenuItem value="UTILITY">Utility</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          select
                          fullWidth
                          label="Language"
                          name="language"
                          value={values.language}
                          onChange={handleChange}
                          variant="outlined"
                          required
                          margin="normal"
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              height: "3rem",
                            },
                          }}
                          disabled={!!templateId}
                        >
                          <MenuItem value="en_US">English (US)</MenuItem>
                          <MenuItem value="es_ES">Spanish</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Body Text"
                          name="bodyText"
                          value={values.bodyText}
                          onChange={handleChange}
                          variant="outlined"
                          required
                          margin="normal"
                          multiline
                          rows={4}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.header}
                              onChange={handleChange}
                              name="header"
                            />
                          }
                          label="Include Header"
                        />
                      </Grid>
                      {values.header && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Header Text"
                            name="headerText"
                            value={values.headerText}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={values.footer}
                              onChange={handleChange}
                              name="footer"
                            />
                          }
                          label="Include Footer"
                        />
                      </Grid>
                      {values.footer && (
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Footer Text"
                            name="footerText"
                            value={values.footerText}
                            onChange={handleChange}
                            variant="outlined"
                            margin="normal"
                          />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <FieldArray
                          name="buttons"
                          render={(arrayHelpers) => (
                            <>
                              <Button
                                type="button"
                                variant="outlined"
                                style={{
                                  borderColor: "black",
                                  color: "black",
                                  marginTop: 10,
                                }}
                                onClick={() =>
                                  arrayHelpers.push({
                                    type: "QUICK_REPLY",
                                    text: "",
                                    phoneNumber: "",
                                    url: "",
                                  })
                                }
                                startIcon={<AddIcon style={{ color: "blue" }} />}
                              >
                                Add Button
                              </Button>
                              {values.buttons.map((button, index) => (
                                <Box key={index} mt={2}>
                                  <TextField
                                    select
                                    fullWidth
                                    label="Button Type"
                                    name={`buttons.${index}.type`}
                                    value={button.type}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                  >
                                    <MenuItem value="QUICK_REPLY">Quick Reply</MenuItem>
                                    <MenuItem value="PHONE_NUMBER">Phone Number</MenuItem>
                                    <MenuItem value="URL">URL</MenuItem>
                                  </TextField>
                                  <TextField
                                    fullWidth
                                    label="Button Text"
                                    name={`buttons.${index}.text`}
                                    value={button.text}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                  />
                                  {button.type === "PHONE_NUMBER" && (
                                    <TextField
                                      fullWidth
                                      label="Phone Number"
                                      name={`buttons.${index}.phoneNumber`}
                                      value={button.phoneNumber}
                                      onChange={handleChange}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                  {button.type === "URL" && (
                                    <TextField
                                      fullWidth
                                      label="URL"
                                      name={`buttons.${index}.url`}
                                      value={button.url}
                                      onChange={handleChange}
                                      variant="outlined"
                                      margin="normal"
                                    />
                                  )}
                                  <Button
                                    type="button"
                                    variant="contained"
                                    color="error"
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    Remove Button
                                  </Button>
                                </Box>
                              ))}
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ backgroundColor: "#24272b", color: "white" }}
                          size="large"
                        >
                          <MDTypography variant="h6" color="white">
                            {templateId ? "Update Template" : "Create Template"}
                          </MDTypography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Card>
        </Container>
      </MDBox>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default CreateTemplate;
