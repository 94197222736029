import React, { useState, useEffect } from "react";
import { Card, Container, IconButton, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, Link } from "react-router-dom";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

const TemplateListPage = () => {
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    const key = "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz";
    try {
      const response = await axios.get(
        "https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/template",
        {
          headers: {
            ContentType: "application/json",
            apiKey: key,
          },
        }
      );
      console.log("Templates fetching response:", response);
      setTemplates(response.data.body || []);
    } catch (error) {
      console.error("Templates fetching error:", error);
      toast.error("Failed to fetch templates. Please try again.");
    }
  };

  const handleEditTemplate = (template) => {
    console.log("templateId in template list=====", template.id);
    navigate(`/templateCreation/${template.id}`);
  };

  const handleDeleteTemplate = async (template) => {
    const key = "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz";
    const apiUrl = `https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/template`;
    try {
      const response = await axios.delete(
        apiUrl,
        {
          params: {
            templateName: template.name,
            templateId: template.id,
          },
        },
        {
          headers: {
            ContentType: "application/json",
            apiKey: key,
          },
        }
      );
      console.log("template delete response====", response);
      toast.success(`Template ${template.name} deleted successfully!`);
      fetchTemplates();
    } catch (error) {
      console.error("Template deletion error:", error);
      toast.error(`Failed to delete template: ${template.name}`);
    }
  };

  const ActionsCell = ({ row }) => (
    <>
      <IconButton onClick={() => handleEditTemplate(row.original)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => handleDeleteTemplate(row.original)}>
        <DeleteIcon />
      </IconButton>
    </>
  );

  ActionsCell.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        category: PropTypes.string.isRequired,
        language: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  const tableData = {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Name", accessor: "name", align: "left" },
      { Header: "Category", accessor: "category", align: "left" },
      { Header: "Language", accessor: "language", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      {
        Header: "Actions",
        accessor: "actions",
        align: "center",
        Cell: ActionsCell,
      },
    ],
    rows: Array.isArray(templates)
      ? templates.map((template) => ({
          id: template.id,
          name: template.name,
          category: template.category,
          language: template.language,
          status: template.status,
          actions: null,
        }))
      : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Container maxWidth="md">
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              style={{ backgroundColor: "black" }}
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h6" color="white">
                Template List
              </MDTypography>
              <Link to="/templateCreation" style={{ textDecoration: "none" }}>
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  style={{ color: "white", borderColor: "white" }}
                >
                  Add Template
                </Button>
              </Link>
            </MDBox>
            <MDBox p={3}>
              <DataTable
                entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
                canSearch
                showTotalEntries
                table={tableData}
                pagination={{ variant: "gradient", color: "info" }}
                isSorted
                noEndBorder
              />
            </MDBox>
          </Card>
        </Container>
      </MDBox>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default TemplateListPage;
