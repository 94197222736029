import React, { useState, useEffect } from "react";
import { Card, Box, Container } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

const MessageListPage = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    const key = "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz";
    const apiUrl =
      "https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/message";

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          ContentType: "application/json",
          apiKey: key,
        },
      });
      console.log("Messages fetching response:", response.data);
      setMessages(response.data.body.messages || []);
    } catch (error) {
      console.error("Messages fetching error:", error);
      toast.error("Failed to fetch messages. Please try again.");
    }
  };

  const tableData = {
    columns: [
      { Header: "ID", accessor: "id", align: "left" },
      { Header: "Status", accessor: "status", align: "left" },
      { Header: "Recipient", accessor: "recipient", align: "left" },
    ],
    rows: Array.isArray(messages)
      ? messages.map((message) => ({
          id: message.id,
          status: JSON.stringify(message.status),
          recipient: message.recipient,
        }))
      : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Container maxWidth="md">
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              style={{ backgroundColor: "black" }}
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Sent Messages Tracking
              </MDTypography>
            </MDBox>
            <MDBox p={3}>
              <DataTable
                entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
                canSearch
                showTotalEntries
                table={tableData}
                pagination={{ variant: "gradient", color: "info" }}
                isSorted
                noEndBorder
              />
            </MDBox>
          </Card>
        </Container>
      </MDBox>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default MessageListPage;
