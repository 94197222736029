import React, { useState, useContext, useEffect } from "react";
import { Card } from "@mui/material";
import { useNavigate, Navigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { UserContext } from "../../../context/userContext.jsx";
import { verifyOTP, sendEmailAfterLogin } from "../../../common/Services/dbServices";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PropTypes from "prop-types";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";

import bgImage from "assets/images/bg-sign-in-basic.jpeg";

const OTPVerification = () => {
  const navigate = useNavigate();
  const { user, setIsVerified, fetchUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [canResend, setCanResend] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const [countdown, setCountdown] = useState(60);

  if (!user || (user && user.providerType !== "local-userpass")) {
    return <Navigate to="/authentication/sign-in" replace />;
  }

  useEffect(() => {
    let timer = null;
    if (!canResend) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer);
            setCanResend(true);
            return 60;
          }
          return prevCountdown - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [canResend]);

  const validationSchema = Yup.object().shape({
    otp: Yup.string()
      .required("OTP is required")
      .matches(/^\d{6}$/, "OTP must be exactly 6 digits and numeric"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const emailVerifyOTPSuccess = (title) => {
    toast.success(title, { duration: 3 });
  };

  const verifyOTPError = (title) => {
    toast.error(title, { duration: 3 });
  };

  const onVerifyOtp = async (data) => {
    setIsLoading(true);
    try {
      const result = await verifyOTP({ email: user?.profile?.email, submittedOtp: data.otp });
      if (result.verified === true) {
        localStorage.setItem("isVerified", JSON.stringify(true));
        setIsVerified(true);
        emailVerifyOTPSuccess(result.message);
        await fetchUser(); // Ensure user state is updated
        setIsLoading(false);
        navigate("/dashboard", { replace: true });
      } else {
        verifyOTPError(result.message);
        setIsLoading(false);
      }
    } catch (error) {
      verifyOTPError(error.message);
      setIsLoading(false);
    }
  };

  const onResendOtp = async () => {
    try {
      if (!canResend) return;
      setResendLoading(true);
      const isEmailSent = await sendEmailAfterLogin({ toEmail: user?.profile?.email });
      if (isEmailSent.status === 202 || 200) {
        setResendLoading(false);
        emailVerifyOTPSuccess("A new OTP has been sent to your email.");
        setCanResend(false);
        setCountdown(60);
      }
    } catch (error) {
      setResendLoading(false);
      setCanResend(false);
      setCountdown(60);
      verifyOTPError("Failed to resend OTP.");
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          style={{ backgroundColor: "#24272b" }}
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Two Factor Authentication
          </MDTypography>
          <MDTypography variant="h6" color="white" mt={1} noWrap>
            Check Your Email: We have sent a 6-digit verification code.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={handleSubmit(onVerifyOtp)}>
            <MDBox mb={2}>
              <Controller
                name="otp"
                control={control}
                render={({ field }) => (
                  <MDInput
                    {...field}
                    type="text"
                    label="Enter OTP"
                    fullWidth
                    maxLength={6}
                    error={errors.otp && Boolean(errors.otp)}
                    helperText={errors.otp && errors.otp.message}
                  />
                )}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                style={{ backgroundColor: "#24272b" }}
                fullWidth
                disabled={isLoading}
              >
                {isLoading ? "Verifying..." : "Verify OTP"}
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton
                type="button"
                variant="outlined"
                color="secondary"
                fullWidth
                disabled={!canResend}
                onClick={onResendOtp}
              >
                {resendLoading ? "Resending..." : `Resend OTP ${canResend ? "" : `(${countdown})`}`}
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
      <ToastContainer />
    </BasicLayout>
  );
};

OTPVerification.propTypes = {
  children: PropTypes.node,
};

export default OTPVerification;
