// // /**
// // =========================================================
// // * Material Dashboard 2 React - v2.2.0
// // =========================================================

// // * Product Page: https://www.creative-tim.com/product/material-dashboard-react
// // * Copyright 2023 Creative Tim (https://www.creative-tim.com)

// // Coded by www.creative-tim.com

// //  =========================================================

// // * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
// // */

// // import { useState, useEffect, useMemo } from "react";

// // // react-router components
// // import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// // // @mui material components
// // import { ThemeProvider } from "@mui/material/styles";
// // import CssBaseline from "@mui/material/CssBaseline";
// // import Icon from "@mui/material/Icon";

// // // Material Dashboard 2 React components
// // import MDBox from "components/MDBox";

// // // Material Dashboard 2 React example components
// // import Sidenav from "examples/Sidenav";
// // import Configurator from "examples/Configurator";

// // // Material Dashboard 2 React themes
// // import theme from "assets/theme";
// // import themeRTL from "assets/theme/theme-rtl";

// // // Material Dashboard 2 React Dark Mode themes
// // import themeDark from "assets/theme-dark";
// // import themeDarkRTL from "assets/theme-dark/theme-rtl";

// // // RTL plugins
// // import rtlPlugin from "stylis-plugin-rtl";
// // import { CacheProvider } from "@emotion/react";
// // import createCache from "@emotion/cache";

// // // Material Dashboard 2 React routes
// // import routes from "routes";

// // // Material Dashboard 2 React contexts
// // import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";

// // // Images
// // import brandWhite from "assets/images/whatsappLight.png";
// // import brandDark from "assets/images/whatsappDark.png";

// // export default function App() {
// //   const [controller, dispatch] = useMaterialUIController();
// //   const {
// //     miniSidenav,
// //     direction,
// //     layout,
// //     openConfigurator,
// //     sidenavColor,
// //     transparentSidenav,
// //     whiteSidenav,
// //     darkMode,
// //   } = controller;
// //   const [onMouseEnter, setOnMouseEnter] = useState(false);
// //   const [rtlCache, setRtlCache] = useState(null);
// //   const { pathname } = useLocation();

// //   // Cache for the rtl
// //   useMemo(() => {
// //     const cacheRtl = createCache({
// //       key: "rtl",
// //       stylisPlugins: [rtlPlugin],
// //     });

// //     setRtlCache(cacheRtl);
// //   }, []);

// //   // Open sidenav when mouse enter on mini sidenav
// //   const handleOnMouseEnter = () => {
// //     if (miniSidenav && !onMouseEnter) {
// //       setMiniSidenav(dispatch, false);
// //       setOnMouseEnter(true);
// //     }
// //   };

// //   // Close sidenav when mouse leave mini sidenav
// //   const handleOnMouseLeave = () => {
// //     if (onMouseEnter) {
// //       setMiniSidenav(dispatch, true);
// //       setOnMouseEnter(false);
// //     }
// //   };

// //   // Change the openConfigurator state
// //   const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

// //   // Setting the dir attribute for the body element
// //   useEffect(() => {
// //     document.body.setAttribute("dir", direction);
// //   }, [direction]);

// //   // Setting page scroll to 0 when changing the route
// //   useEffect(() => {
// //     document.documentElement.scrollTop = 0;
// //     document.scrollingElement.scrollTop = 0;
// //   }, [pathname]);

// //   const getRoutes = (allRoutes) =>
// //     allRoutes.map((route) => {
// //       if (route.collapse) {
// //         return getRoutes(route.collapse);
// //       }

// //       if (route.route) {
// //         return <Route exact path={route.route} element={route.component} key={route.key} />;
// //       }

// //       return null;
// //     });

// //   const configsButton = (
// //     <MDBox
// //       display="flex"
// //       justifyContent="center"
// //       alignItems="center"
// //       width="3.25rem"
// //       height="3.25rem"
// //       bgColor="white"
// //       shadow="sm"
// //       borderRadius="50%"
// //       position="fixed"
// //       right="2rem"
// //       bottom="2rem"
// //       zIndex={99}
// //       color="dark"
// //       sx={{ cursor: "pointer" }}
// //       onClick={handleConfiguratorOpen}
// //     >
// //       <Icon fontSize="small" color="inherit">
// //         settings
// //       </Icon>
// //     </MDBox>
// //   );

// //   return direction === "rtl" ? (
// //     <CacheProvider value={rtlCache}>
// //       <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
// //         <CssBaseline />
// //         {layout === "dashboard" && (
// //           <>
// //             <Sidenav
// //               color={sidenavColor}
// //               brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
// //               brandName="WhatsApp Wrapper"
// //               routes={routes}
// //               onMouseEnter={handleOnMouseEnter}
// //               onMouseLeave={handleOnMouseLeave}
// //             />
// //             <Configurator />
// //             {configsButton}
// //           </>
// //         )}
// //         {layout === "vr" && <Configurator />}
// //         <Routes>
// //           {getRoutes(routes)}
// //           <Route path="*" element={<Navigate to="/dashboard" />} />
// //         </Routes>
// //       </ThemeProvider>
// //     </CacheProvider>
// //   ) : (
// //     <ThemeProvider theme={darkMode ? themeDark : theme}>
// //       <CssBaseline />
// //       {layout === "dashboard" && (
// //         <>
// //           <Sidenav
// //             color={sidenavColor}
// //             brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
// //             brandName="WhatsApp Wrapper"
// //             routes={routes}
// //             onMouseEnter={handleOnMouseEnter}
// //             onMouseLeave={handleOnMouseLeave}
// //           />
// //           <Configurator />
// //           {configsButton}
// //         </>
// //       )}
// //       {layout === "vr" && <Configurator />}
// //       <Routes>
// //         {getRoutes(routes)}
// //         <Route path="*" element={<Navigate to="/dashboard" />} />
// //       </Routes>
// //     </ThemeProvider>
// //   );
// // }
import React, { useState, useEffect, useMemo, useContext } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import routes from "routes";
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brandWhite from "assets/images/whatsappLight.png";
import brandDark from "assets/images/whatsappDark.png";
import ProtectedRoute from "./common/protectedRoute";
import { UserContext } from "./context/userContext";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();
  const { fetchUser, user, isVerified } = useContext(UserContext);

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    if (!user) {
      fetchUser();
    }
  }, [fetchUser, user]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={
              route.protected ? <ProtectedRoute>{route.component}</ProtectedRoute> : route.component
            }
            key={route.key}
          />
        );
      }

      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="WhatsApp Wrapper"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="WhatsApp Wrapper"
            routes={routes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/authentication/sign-in" />} />
      </Routes>
    </ThemeProvider>
  );
}
