import React, { useState, useEffect } from "react";
import { Card, Container, TextField, Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";

const AddContactGroup = () => {
  const { groupId } = useParams();
  const [groupName, setGroupName] = useState("");
  const [contactIds, setContactIds] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (groupId) {
      fetchGroupDetails();
    }
  }, [groupId]);

  const fetchGroupDetails = async () => {
    try {
      const response = await axios.get(
        `https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact_group`,
        {
          headers: {
            "Content-Type": "application/json",
            apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
          },
          params: { groupId: groupId },
        }
      );
      const group = response.data.body.group;
      setGroupName(group.groupName);
      setContactIds(group.contactIds.join(", "));
    } catch (error) {
      console.error("Group fetching error:", error);
      toast.error("Failed to fetch group details. Please try again.");
    }
  };

  const handleAddGroup = async () => {
    const apiUrl = `https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact_group`;

    try {
      const contactIdsArray = contactIds.split(",").map((id) => id.trim());
      const body = { groupName, contactIds: contactIdsArray };
      await axios.post(apiUrl, body, {
        headers: {
          "Content-Type": "application/json",
          apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
        },
      });
      toast.success(`Group ${groupName} created successfully!`);
      navigate("/contactList");
    } catch (error) {
      console.error("Group creating error:", error);
      toast.error("Failed to create group. Please try again.");
    }
  };

  const handleEditGroup = async () => {
    const apiUrl = `https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact_group`;

    try {
      const contactIdsArray = contactIds.split(",").map((id) => id.trim());
      const body = { groupId, groupName, contactIds: contactIdsArray };
      await axios.put(apiUrl, body, {
        headers: {
          "Content-Type": "application/json",
          apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
        },
      });
      toast.success(`Group ${groupName} updated successfully!`);
      navigate("/contactList");
    } catch (error) {
      console.error("Group updating error:", error);
      toast.error("Failed to update group. Please try again.");
    }
  };

  const handleSaveGroup = () => {
    if (groupId) {
      handleEditGroup();
    } else {
      handleAddGroup();
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Container maxWidth="md">
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              style={{ backgroundColor: "black" }}
              borderRadius="lg"
              coloredShadow="info"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDTypography variant="h6" color="white">
                {groupId ? "Edit" : "Add"} Contact Group
              </MDTypography>
            </MDBox>
            <MDBox p={3}>
              <TextField
                label="Group Name"
                fullWidth
                margin="normal"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
              <TextField
                label="Contact IDs (comma separated)"
                fullWidth
                margin="normal"
                value={contactIds}
                onChange={(e) => setContactIds(e.target.value)}
              />
              <MDBox mt={3} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#24272b", color: "white" }}
                  onClick={handleSaveGroup}
                >
                  {groupId ? "Save" : "Add"} Group
                </Button>
              </MDBox>
            </MDBox>
          </Card>
        </Container>
      </MDBox>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default AddContactGroup;
