import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { UserContext } from "../context/userContext";
import PropTypes from "prop-types";

const ProtectedRoute = ({ children }) => {
  const { user, isVerified } = useContext(UserContext);

  if (!user) {
    return <Navigate to="/authentication/sign-in" />;
  }

  if (!isVerified) {
    return <Navigate to="/auth/2fa" />;
  }

  return children;
};
// Typechecking props for the ProtectedRoute
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ProtectedRoute;
