// import React, { useState, useEffect } from "react";
// import { Card, Container, IconButton, Button } from "@mui/material";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import axios from "axios";
// import PropTypes from "prop-types";
// import EditIcon from "@mui/icons-material/Edit";
// import DeleteIcon from "@mui/icons-material/Delete";
// import AddIcon from "@mui/icons-material/Add";
// import { useNavigate, Link } from "react-router-dom";

// import MDBox from "components/MDBox";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import MDTypography from "components/MDTypography";
// import DataTable from "examples/Tables/DataTable";

// const ContactListPage = () => {
//   const [contacts, setContacts] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     fetchContacts();
//   }, []);

//   const fetchContacts = async () => {
//     try {
//       const response = await axios.get(
//         "https://asia-south1.gcp.data.mongodb-api.com/app/admin-wdnygxk/endpoint/v1/contact/list"
//       );
//       console.log("Contacts fetching response:", response.data.body.contacts);
//       setContacts(response.data.body.contacts || []);
//     } catch (error) {
//       console.error("Contacts fetching error:", error);
//       toast.error("Failed to fetch contacts. Please try again.");
//     }
//   };

//   const handleEditContact = (contact) => {
//     console.log("contactId in contact list=====", contact._id);
//     navigate(`/contactCreation/${contact._id}`);
//   };

//   const handleDeleteContact = async (contact) => {
//     const apiUrl = `https://asia-south1.gcp.data.mongodb-api.com/app/admin-wdnygxk/endpoint/v1/contact/delete`;
//     try {
//       const response = await axios.delete(apiUrl, {
//         params: {
//           contactId: contact._id,
//         },
//       });
//       console.log("contact delete response====", response);
//       toast.success(`Contact ${contact.firstName} ${contact.lastName} deleted successfully!`);
//       fetchContacts();
//     } catch (error) {
//       console.error("Contact deletion error:", error);
//       toast.error(`Failed to delete contact: ${contact.firstName} ${contact.lastName}`);
//     }
//   };

//   const ActionsCell = ({ row }) => (
//     <>
//       <IconButton onClick={() => handleEditContact(row.original)}>
//         <EditIcon />
//       </IconButton>
//       <IconButton onClick={() => handleDeleteContact(row.original)}>
//         <DeleteIcon />
//       </IconButton>
//     </>
//   );

//   ActionsCell.propTypes = {
//     row: PropTypes.shape({
//       original: PropTypes.shape({
//         id: PropTypes.string.isRequired,
//         firstName: PropTypes.string.isRequired,
//         lastName: PropTypes.string.isRequired,
//         number: PropTypes.string.isRequired,
//       }).isRequired,
//     }).isRequired,
//   };

//   const tableData = {
//     columns: [
//       { Header: "First Name", accessor: "firstName", align: "left" },
//       { Header: "Last Name", accessor: "lastName", align: "left" },
//       { Header: "Phone Number", accessor: "phoneNumber", align: "left" },
//       {
//         Header: "Actions",
//         accessor: "actions",
//         align: "center",
//         Cell: ActionsCell,
//       },
//     ],
//     rows: Array.isArray(contacts)
//       ? contacts.map((contact) => ({
//           id: contact._id,
//           firstName: contact.firstName,
//           lastName: contact.lastName,
//           phoneNumber: contact.number,
//           actions: null,
//         }))
//       : [],
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox py={3}>
//         <Container maxWidth="md">
//           <Card>
//             <MDBox
//               mx={2}
//               mt={-3}
//               py={3}
//               px={2}
//               variant="gradient"
//               style={{ backgroundColor: "black" }}
//               borderRadius="lg"
//               coloredShadow="info"
//               display="flex"
//               justifyContent="space-between"
//               alignItems="center"
//             >
//               <MDTypography variant="h6" color="white">
//                 Contact List
//               </MDTypography>
//               <Link to="/contactCreation" style={{ textDecoration: "none" }}>
//                 <Button
//                   variant="outlined"
//                   startIcon={<AddIcon />}
//                   style={{ color: "white", borderColor: "white" }}
//                 >
//                   Add Contact
//                 </Button>
//               </Link>
//             </MDBox>
//             <MDBox p={3}>
//               <DataTable
//                 entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
//                 canSearch
//                 showTotalEntries
//                 table={tableData}
//                 pagination={{ variant: "gradient", color: "info" }}
//                 isSorted
//                 noEndBorder
//               />
//             </MDBox>
//           </Card>
//         </Container>
//       </MDBox>
//       <ToastContainer />
//     </DashboardLayout>
//   );
// };

// export default ContactListPage;
import React, { useState, useEffect } from "react";
import {
  Card,
  Container,
  IconButton,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate, Link } from "react-router-dom";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

const ContactListPage = () => {
  const [accounts, setAccounts] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const response = await axios.get(
        "https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact_list",
        {
          headers: {
            "Content-Type": "application/json",
            apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
          },
        }
      );
      setAccounts(response.data.body.accounts || []);
    } catch (error) {
      console.error("Accounts fetching error:", error);
      toast.error("Failed to fetch accounts. Please try again.");
    }
  };

  const fetchContacts = async (accountId) => {
    try {
      const response = await axios.get(
        `https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact`,
        {
          headers: {
            "Content-Type": "application/json",
            apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
          },
        }
      );
      setContacts(response.data.body.contacts || []);
    } catch (error) {
      console.error("Contacts fetching error:", error);
      toast.error("Failed to fetch contacts. Please try again.");
    }
  };

  const handleAccountClick = (accountId) => {
    setSelectedAccount(accountId);
    fetchContacts(accountId);
  };

  const handleEditContact = (contact) => {
    navigate(`/contactCreation/${contact._id}`);
  };

  const handleDeleteContact = async (contact) => {
    const apiUrl = `https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact`;
    try {
      await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
        },
        params: {
          contactId: contact._id,
        },
      });
      toast.success(`Contact ${contact.firstName} ${contact.lastName} deleted successfully!`);
      fetchContacts(selectedAccount);
    } catch (error) {
      console.error("Contact deletion error:", error);
      toast.error(`Failed to delete contact: ${contact.firstName} ${contact.lastName}`);
    }
  };

  const handleEditGroup = (account) => {
    navigate(`/contactGroupCreation/${account._id}`);
  };

  const handleDeleteGroup = async (account) => {
    const apiUrl = `https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact_group`;
    try {
      await axios.delete(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
        },
        params: {
          groupId: account._id,
        },
      });
      toast.success(`Group ${account.name} deleted successfully!`);
      fetchAccounts();
      setContacts([]);
    } catch (error) {
      console.error("Group deletion error:", error);
      toast.error(`Failed to delete group: ${account.name}`);
    }
  };

  const ActionsCell = ({ row }) => (
    <>
      <IconButton onClick={() => handleEditContact(row.original)}>
        <EditIcon />
      </IconButton>
      <IconButton onClick={() => handleDeleteContact(row.original)}>
        <DeleteIcon />
      </IconButton>
    </>
  );

  ActionsCell.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string.isRequired,
        firstName: PropTypes.string.isRequired,
        lastName: PropTypes.string.isRequired,
        number: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  const tableData = {
    columns: [
      { Header: "First Name", accessor: "firstName", align: "left" },
      { Header: "Last Name", accessor: "lastName", align: "left" },
      { Header: "Phone Number", accessor: "phoneNumber", align: "left" },
      {
        Header: "Actions",
        accessor: "actions",
        align: "center",
        Cell: ActionsCell,
      },
    ],
    rows: Array.isArray(contacts)
      ? contacts.map((contact) => ({
          id: contact._id,
          firstName: contact.firstName,
          lastName: contact.lastName,
          phoneNumber: contact.number,
          actions: null,
        }))
      : [],
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Container maxWidth="lg">
          <Card>
            <Grid container>
              <Grid item xs={4}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  style={{ backgroundColor: "black" }}
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Accounts
                  </MDTypography>
                  <IconButton
                    onClick={() => navigate("/contactGroupCreation")}
                    style={{ color: "white" }}
                  >
                    <AddIcon />
                  </IconButton>
                </MDBox>
                <List>
                  {accounts.map((account) => (
                    <ListItem
                      button
                      key={account._id}
                      onClick={() => handleAccountClick(account._id)}
                    >
                      <ListItemText primary={account.name} />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => handleEditGroup(account)} edge="end">
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteGroup(account)} edge="end">
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={7}>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  style={{ backgroundColor: "black" }}
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h6" color="white">
                    Contacts
                  </MDTypography>
                  <Link to="/contactCreation" style={{ textDecoration: "none" }}>
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      style={{ color: "white", borderColor: "white" }}
                    >
                      Add Contact
                    </Button>
                  </Link>
                </MDBox>
                <MDBox p={3}>
                  <DataTable
                    entriesPerPage={{ defaultValue: 10, entries: [5, 10, 15, 20, 25] }}
                    canSearch
                    showTotalEntries
                    table={tableData}
                    pagination={{ variant: "gradient", color: "info" }}
                    isSorted
                    noEndBorder
                  />
                </MDBox>
              </Grid>
            </Grid>
          </Card>
        </Container>
      </MDBox>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default ContactListPage;
