import React, { useEffect, useState } from "react";
import { TextField, Card, Box, Container, Button } from "@mui/material";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";

import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";

const AddContact = () => {
  const { contactId } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    number: "",
  });

  useEffect(() => {
    const fetchContact = async () => {
      if (contactId) {
        console.log("Fetching contact with ID:", contactId);
        const apiUrl = `https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact`;
        try {
          const response = await axios.get(apiUrl, {
            headers: {
              "Content-Type": "application/json",
              apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
            },
            params: { contactId: contactId },
          });
          console.log("Response of fetching contact:", response.data.body);
          const { firstName, lastName, number } = response.data.body.contact;
          setInitialValues({ firstName, lastName, number });
        } catch (error) {
          console.error("Error fetching contact:", error);
          toast.error("Failed to fetch contact. Please try again.");
        }
      }
    };

    fetchContact();
  }, [contactId]);

  const handleCreateContact = async (values, resetForm) => {
    const apiUrl =
      "https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact";
    const { firstName, lastName, number } = values;
    try {
      const response = await axios.post(
        apiUrl,
        { firstName, lastName, number },
        {
          headers: {
            "Content-Type": "application/json",
            apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
          },
        }
      );
      console.log("Response of contact creation:", response);
      toast.success("Contact created successfully!");
      resetForm();
      navigate("/contactList");
    } catch (error) {
      console.error("Contact creation error:", error);
      toast.error("Failed to create contact. Please try again.");
    }
  };

  const handleEditContact = async (values, resetForm) => {
    const apiUrl =
      "https://asia-south1.gcp.data.mongodb-api.com/app/middleware-owprapu/endpoint/v1/contact";
    const { firstName, lastName, number } = values;
    try {
      const response = await axios.put(
        apiUrl,
        { contactId, firstName, lastName, number },
        {
          headers: {
            "Content-Type": "application/json",
            apiKey: "pb85BJ4tbG1oJ5ZMsMn0RmmrGHu5ejVxG9Nibgy5oirPDPy0Gn3d8msjZXcUfgBz",
          },
        }
      );
      console.log("Response of contact editing:", response);
      toast.success("Contact updated successfully!");
      resetForm();
      navigate("/contactList");
    } catch (error) {
      console.error("Contact editing error:", error);
      toast.error("Failed to update contact. Please try again.");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Container maxWidth="md">
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              style={{ backgroundColor: "#24272b" }}
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                {contactId ? "Edit Contact" : "Add Contact"}
              </MDTypography>
            </MDBox>
            <Box p={3}>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={(values, { resetForm }) => {
                  contactId
                    ? handleEditContact(values, resetForm)
                    : handleCreateContact(values, resetForm);
                }}
              >
                {({ handleChange, values }) => (
                  <Form>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="First Name"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          variant="outlined"
                          required
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Last Name"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          variant="outlined"
                          required
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Phone Number"
                          name="number"
                          value={values.number}
                          onChange={handleChange}
                          variant="outlined"
                          required
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button
                          type="submit"
                          variant="contained"
                          style={{ backgroundColor: "#24272b", color: "white" }}
                          size="large"
                        >
                          <MDTypography variant="h6" color="white">
                            {contactId ? "Update Contact" : "Add Contact"}
                          </MDTypography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Box>
          </Card>
        </Container>
      </MDBox>
      <ToastContainer />
    </DashboardLayout>
  );
};

export default AddContact;
